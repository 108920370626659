<script>
    import { Router, Route } from "svelte-routing";
  
    // components for this layout
    import DesenhistasNavbar from "components/Navbars/AdminNavbar.svelte";
    import DesenhistasSidebar from "components/Sidebar/DesenhistasSidebar.svelte";
    import HeaderStats from "components/Headers/HeaderStats.svelte";
    import FooterDesenhistas from "components/Footers/FooterDesenhistas.svelte";
    
  
    import Pedidos from "views/desenhistas/pedidos.svelte";
    import Videoaulas from "views/desenhistas/videoaulas.svelte";
    import Portaldosdesenhistas from "views/desenhistas/portaldosdesenhistas.svelte";
    import Revistacaricanews from "views/desenhistas/revistacaricanews.svelte";
  
  
    export let location;
    export let desenhistas = "";
  </script>
  
  <div>
    <style>
    .icon_menu{
      color: #9ed923;
    }
    .nav_color {
      background-color: #1a1a27; color: #fff; font-family:Arial;
    }
    .submenu {
        background-color: #0b0b1c;
    }
    
    </style>
  
  
    <DesenhistasSidebar location={location}/>
    <div class="relative md:ml-[191px] bg-blueGray-100">
      <DesenhistasNavbar />
      <HeaderStats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
  
          <Router url="desenhistas">
          <Route path="pedidos" component={Pedidos} />
          <Route path="videoaulas" component={Videoaulas} />
          <Route path="portaldosdesenhistas" component={Portaldosdesenhistas} />
          <Route path="revistacaricanews" component={Revistacaricanews} />
  
          </Router>
  
  
        <!-- <FooterDesenhistas /> -->
        
      </div>
    </div>
  </div>