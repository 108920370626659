<script>
  // core components
  import CardStats from "components/Cards/CardStats.svelte";
  import { onMount } from "svelte";


let jsonData = {};

async function fetchData() {
  try {
    const response = await fetch('https://app.caricanecas.com.br/crud/select/select-dashboard-novo.php?filtro=topo&mes=07&ano=2023');
    const responseData = await response.json();
    jsonData = responseData[0];

    console.log("jsonData:", jsonData);
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
  }
}

onMount(() => {
  // Inicialmente, busca os dados
  fetchData();

  // A cada 10 segundos, faz a chamada para buscar os dados novamente
  setInterval(fetchData, 10000); // 10000 milissegundos = 10 segundos
});
</script>

<!--
{#await jsonData}
          <p>Carregando dados...</p>
        {:then data}
          {data.top_total_receber}
        {:catch error}
          <p>Erro ao carregar dados: {error.message}</p>
        {/await}-->

<!-- Header -->
<div class="relative bg-red-500 md:pt-32 pb-32 pt-12">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="TRAFFIC"
            statTitle={jsonData.top_total_receber}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month2"
            statIconName="far fa-chart-bar"
            statIconColor="bg-red-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="NEW USERS"
            statTitle={jsonData.top_total_recebido}
            statArrow="down"
            statPercent="3.48"
            statPercentColor="text-red-500"
            statDescripiron="Since last week"
            statIconName="fas fa-chart-pie"
            statIconColor="bg-orange-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="SALES"
            statTitle={jsonData.top_total_receber_can}
            statArrow="down"
            statPercent="1.10"
            statPercentColor="text-orange-500"
            statDescripiron="Since yesterday"
            statIconName="fas fa-users"
            statIconColor="bg-pink-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="PERFORMANCE"
            statTitle={jsonData.top_total_receber_dev}
            statArrow="up"
            statPercent="12"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fas fa-percent"
            statIconColor="bg-emerald-500"
          />
        </div>
      </div>
    </div>
  </div>
</div>
