<script>
    import { Router, Route } from "svelte-routing";
  
    // components for this layout
    import FranqueadosNavbar from "components/Navbars/AdminNavbar.svelte";
    import FranqueadosSidebar from "components/Sidebar/FranqueadosSidebar.svelte";
    import HeaderStats from "components/Headers/HeaderStats.svelte";
    import FooterFranqueados from "components/Footers/FooterFranqueados.svelte";
    
  
    import Cursos from "views/franqueados/cursos.svelte";
    import Pedidos from "views/franqueados/pedidos.svelte";
    import Reuniaotrafego from "views/franqueados/reuniaotrafego.svelte";
    import Atualizacoesmensais from "views/franqueados/atualizacoesmensais.svelte";
    import Programacaosemanal from "views/franqueados/programacaosemanal.svelte";
    import Reuniaosemanal from "views/franqueados/reuniaosemanal.svelte";
    import Campanhasdemarketing from "views/franqueados/campanhasdemarketing.svelte";
    import Caricapediaa from "views/franqueados/caricapediaa.svelte";
    import Materialdeapoio from "views/franqueados/materialdeapoio.svelte";
    import Design from "views/franqueados/design.svelte";
    import Vendasonline from "views/franqueados/vendasonline.svelte";
    import Produtos from "views/franqueados/produtos.svelte";
    import Clientes from "views/franqueados/clientes.svelte";
    import Configuracoes from "views/franqueados/layoutmaker.svelte";
    import Cupons from "views/franqueados/configuracoes.svelte";
    import Saldoecommerce from "views/franqueados/saldoecommerce.svelte";
    import Lpvendass from "views/franqueados/lpvendass.svelte";
    import Minhascobrancas from "views/franqueados/minhascobrancas.svelte";
  
  
  
    export let location;
    export let franqueados = "";
  </script>
  
  <div>
    <style>
    .icon_menu{
      color: #9ed923;
    }
    .nav_color {
      background-color: #1a1a27; color: #fff; font-family:Arial;
    }
    .submenu {
        background-color: #0b0b1c;
    }
    
    </style>
  
  
  <FranqueadosSidebar location={location}/>
    <div class="relative md:ml-[191px] bg-blueGray-100">
      <FranqueadosNavbar />
      <HeaderStats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
  
          <Router url="franqueados">
          <Route path="cursos" component={Cursos} />
          <Route path="reuniaotrafego" component={Reuniaotrafego} />
          <Route path="reuniaosemanal" component={Reuniaosemanal} />
          <Route path="atualizacoesmensais" component={Atualizacoesmensais} />
          <Route path="programacaosemanal" component={Programacaosemanal} />
          <Route path="campanhasdemarketing" component={Campanhasdemarketing} />
          <Route path="caricapediaa" component={Caricapediaa} />
          <Route path="materialdeapoio" component={Materialdeapoio} />
          <Route path="design" component={Design} />
          <Route path="vendasonline" component={Vendasonline} />
          <Route path="produtos" component={Produtos} />
          <Route path="clientes" component={Clientes} />
          <Route path="configuracoes" component={Configuracoes} />
          <Route path="cupons" component={Cupons} />
          <Route path="saldoecommerce" component={Saldoecommerce} />
          <Route path="lpvendass" component={Lpvendass} />
          <Route path="minhascobrancas" component={Minhascobrancas} />
          <Route path="pedidos" component={Pedidos} />
  
  
  
  
  
  
          </Router>
  
  
          

        <!-- <FooterFranqueados /> -->
      </div>
    </div>
  </div>
  
  