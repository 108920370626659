<script>
    import { link } from "svelte-routing";
  
    // core components
    import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
    import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
  
    let collapseShow = "hidden";
  
    function toggleCollapseShow(classes) {
      collapseShow = classes;
    }
  
    export let location;
  
    function dropdown(event) {
      const submenu = event.currentTarget.nextElementSibling;
      const arrow = event.currentTarget.querySelector(".dropdown-arrow");
  
      submenu.classList.toggle("hidden");
      arrow.classList.toggle("rotate-0");
    }
  
    function handleKeyPress(event) {
      if (event.key === "Enter" || event.key === " ") {
        dropdown(event);
      }
    }
  </script>
  
  <nav
    class="nav_color bg-[#1a1a27] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-menu flex flex-wrap items-center justify-between relative md:w-[190px] z-10 py-4"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
      >
        <i class="fas fa-bars" />
      </button>
      <!-- Brand -->
      <a
        use:link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        href="/"
      >
        <img
          src="https://app.caricanecas.com.br/assets/header-logo.png"
          alt="Caricanecas"
        />
      </a>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <NotificationDropdown />
        </li>
        <li class="inline-block relative">
          <UserDropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <a
                use:link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                href="/"
              >
                Notus Svelte
              </a>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                on:click={() => toggleCollapseShow("hidden")}
              >
                <i class="fas fa-times" />
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>
  
        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
  
 
  
            <!-- Heading -->
            <h6
              class="mt-10 md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
            >
              MENU FRANQUEADOS
            </h6>
  
            <!-- Navigation -->
  
            <ul class="md:flex-col md:min-w-full flex flex-col list-none">
              <li class="items-center">
                <a
                  use:link
                  href="/franqueados/dashboard"
                  class="text-xs uppercase py-3 px-4 font-bold block px-4 {location.href.indexOf(
                    '/franqueados/dashboard'
                  ) !== -1
                    ? 'text-green-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/dashboard'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Dashboard
                </a>
              </li>
  
              <!-- FIM - PÁGINA DASHBOARD -->
  
              <!-- DROPDOWN CARICATURAS -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                
              <div
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/pedidos'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/pedidos'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Caricaturas
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA PEDIDOS DE CARICATURAS -->
                <a
                  use:link
                  href="/franqueados/operacional/pedidos"
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/pedidos'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/pedidos'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Pedidos de caricaturas
                </a>
  
                <!--FIM - PÁGINA PEDIDOS DE CARICATURAS -->
              </div>
  
              <!-- FIM - CARICATURAS-->
  
              <!-- DROPDOWN TREINAMENTOS -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
              <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/franqueados/cursos' || location.pathname ===
              '/franqueados/atualizacoesmensais' || location.pathname ===
              '/franqueados/reuniaotrafego' || location.pathname ===
              '/franqueados/reuniaosemanal'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/franqueados'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Treinamentos
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA DE CURSOS -->
                <a
                  use:link
                  href="/franqueados/cursos"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/cursos'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/cursos'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Cursos
                </a>
  
                <!--FIM - PÁGINA PEDIDOS DE CURSOS-->
  
                <!-- PÁGINA ATT MENSAIS -->
                <a
                  use:link
                  href="/franqueados/atualizacoesmensais"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/atualizacoesmensais'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/atualizacoesmensais'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Atualizações Mensais
                </a>
  
                <!--FIM - PÁGINA ATT MENSAIS-->
  
                <!-- PÁGINA REUNIÕES TRÁFEGO -->
                <a
                  use:link
                  href="/franqueados/reuniaotrafego"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/admin/franqueados/reuniaotrafego'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/admin/franqueados/reuniaotrafego'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Reunião de Tráfego
                </a>
  
                <!--FIM - PÁGINA REUNIÕES TRÁFEGO-->
  
                <!-- PÁGINA REUNIÕES SEMANASIS -->
                <a
                  use:link
                  href="/franqueados/reuniaosemanal"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/reuniaosemanal'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/reuniaosemanal'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Reunião Semanal
                </a>
  
                <!--FIM - PÁGINA REUNIÃO SEMANAL-->
              </div>
  
              <!-- FIM - TREINAMENTOS -->
  
              <!-- DROPDOWN MARKETING -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/programacaosemanal' || location.pathname ===
                '/franqueados/campanhasdemarketing' || location.pathname ===
                '/franqueados/caricapedia' || location.pathname ===
                '/franqueados/materialdeapoio'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/marketing'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Marketing
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA PROGRAMAÇÃO SEMANAL -->
                <a
                  use:link
                  href="/franqueados/programacaosemanal"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/programacaosemanal'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/programacaosemanal'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Programação Semanal
                </a>
  
                <!--FIM - PROGRAMAÇÃO SEMANAL-->
  
  
  
  
                <!-- PÁGINA CAMPANHAS DE MARKETING -->
                <a
                  use:link
                  href="/franqueados/campanhasdemarketing"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/campanhasdemarketing'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/campanhasdemarketing'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Campanhas de Marketing
                </a>
  
                <!--FIM - CAMPANHAS DE MARKETING -->
  
                <!-- PÁGINA CARICAPEDIA -->
                <a
                  use:link
                  href="/franqueados/operacional/caricapedia"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/caricapedia'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/caricapedia'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Caricapedia
                </a>
  
                <!--FIM - CARICAPEDIA -->
  
                <!-- PÁGINA MATERIAL DE APOIO -->
                <a
                  use:link
                  href="/franqueados/operacional/materialdeapoio"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/materialdeapoio'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/materialdeapoio'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Material de Apoio
                </a>
                <!--FIM PÁGINA MATERIAL DE APOIO -->
  
  
  
  
              </div>
              <!-- FIM - DROPDOWN MARKETING -->
  
  
  
  
              
  
              <!-- DROPDOWN SERVIÇOS -->
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                <div
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                    '/franqueados/esign' ||
                  location.pathname === '/franqueados/gestaodetrafego'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/servicos'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Serviços
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA DESIGN -->
                <a
                  use:link
                  href="/franqueados/design"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/design'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/design'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Design
                </a>
  
                <!--FIM PÁGINA  DESIGN-->
  
                <!-- PÁGINA GESTÃO TRÁFEGO -->
                <a
                  use:link
                  href="/franqueados/gestaodetrafego"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/gestaodetrafego'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/gestaodetrafego'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Gestão de Tráfego
                </a>
  
                <!--FIM PÁGINA GESTÃO TRÁFEGO -->
              </div>
  
              <!-- FIM - DROPDOWN SERVIÇOS -->
  
              <!-- DROPDOWN E-COMMERCE -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                <div
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                    '/franqueados/vendasonline' ||
                  location.pathname === '/franqueados/produtos' ||
                  location.pathname === '/franqueados/operacional/clientes' ||
                  location.pathname ===
                    '/franqueados/configuracoes' ||
                  location.pathname === '/franqueados/cupons' ||
                  location.pathname === '/franqueados/ecommerce'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/ecommerce'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  e-commerce
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA VENDAS ONLINE -->
                <a
                  use:link
                  href="/franqueados/vendasonline"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/vendasonline'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/vendasonline'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Vendas Online
                </a>
  
                <!--FIM PÁGINA VENDAS ONLINE -->
  
                <!-- PÁGINA PRODUTOS -->
                <a
                  use:link
                  href="/franqueados/produtos"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/produtos'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/produtos'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Produtos
                </a>
  
                <!--FIM PÁGINA PRODUTOS -->
  
                <!-- PÁGINA CLIENTES -->
                <a
                  use:link
                  href="/franqueados/clientes"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/clientes'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/clientes'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Clientes
                </a>
  
                <!--FIM PÁGINA CLIENTES  -->
  
                <!-- PÁGINA CONFIGURAÇÕES -->
                <a
                  use:link
                  href="/franqueados/configuracoes"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/configuracoes'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/configuracoes'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Configurações
                </a>
  
                <!--FIM PÁGINA CONFIGURAÇÕES  -->
  
                <!-- PÁGINA CUPONS -->
                <a
                  use:link
                  href="/franqueados/cupons"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/cupons'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/ocupons'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Cupons
                </a>
  
                <!--FIM PÁGINA CUPONS  -->
  
                <!-- PÁGINA SALDO E-COMMERCE -->
                <a
                  use:link
                  href="/franqueados/ecommerce"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/ecommerce'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/ecommerce'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  e-commerce
                </a>
  
                <!--FIM PÁGINA E-COMMERCE  -->
              </div>
  
              <!--FIM DROPDOWN E-COMMERCE  -->
  
              <!-- DROPDOWN FERRAMENTAS -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                <div
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/lpvendas'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/ferramentas'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Ferramentas
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA LP VENDAS -->
                <a
                  use:link
                  href="/franqueados/lpvendas"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/lpvendas'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/lpvendas'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  LP Vendas
                </a>
  
                <!--FIM PÁGINA LP VENDAS -->
              </div>
  
              <!--FIM PÁGINA DROPDOWN FERRAMENTAS -->
  
              <!-- DROPDOWN FINANCEIRO -->
  
              <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
              <li
                class="items-center dropdown-toggle"
                on:click={dropdown}
                on:keypress={handleKeyPress}
                tabindex="0"
              >
                <div
                  class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/minhascobrancas'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                      '/franqueados/financeiro'
                    ) !== -1
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Financeiro
                  <span class="text-sm rotate-180 dropdown-arrow">
                    <i class="bi bi-chevron-down" />
                  </span>
                </div>
              </li>
  
              <div class="submenu bg-sub-menu hidden">
                <!-- PÁGINA MINHAS COBRANÇAS -->
                <a
                  use:link
                  href="/franqueados/minhascobrancas"
                  class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/ominhascobrancas'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                  <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/franqueados/minhascobrancas'
                      ? 'opacity-75'
                      : 'text-blueGray-300'}"
                  />
                  Minhas Cobranças
                </a>
  
                <!--FIM PÁGINA MINHAS COBRANÇAS -->
              </div>
  
              <!--FIM PÁGINA DROPDOWN FINANCEIRO -->
  
  
            </ul>
              <!-- FIM MENU FRANQUEADOS -->
  
              
      </div>
    </div>
  </nav>