<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
  import Settings from "views/admin/Settings.svelte";
  import Tables from "views/admin/Tables.svelte";
  import teste from "views/admin/teste.svelte";
  import teste2 from "views/admin/teste2.svelte";
  import Maps from "views/admin/Maps.svelte";
  import Pedidosdecaricaturas from "views/admin/operacional/pedidos.svelte";
  // import Corpinhospersonalizados from "views/desenhistas/corpinhos-personalizados/corpinhospersonalizados.svelte";
  import Eventos from "views/admin/eventos.svelte";
  import Credenciamentootr from "views/admin/credenciamentootr.svelte";
  import Programacaosemanal from "views/admin/programacaosemanal.svelte";
  import Caricapedia from "views/admin/caricapedia.svelte";
  import Minhacarteira from "views/admin/minhacarteira.svelte";
  import Desempenho from "views/admin/desempenho.svelte";
  import Reunioes from "views/admin/reunioes.svelte";
  import Interacoes from "views/admin/interacoes.svelte";
  import Retencoes from "views/admin/retencoes.svelte";
  import Saidas from "views/admin/saidas.svelte";
  import Diversas from "views/admin/diversas.svelte";
  import Entradanovos from "views/admin/entradanovos.svelte";
  import Credenciamentosotr from "views/admin/credenciamentosotr.svelte";
  import Funiltypeform from "views/admin/funiltypeform.svelte";
  import Designs from "views/admin/designs.svelte";
  import Creditosdesign from "views/admin/creditosdesign.svelte";
  import Lpvendas from "views/admin/lpvendas.svelte";
  import Gestaotrafego from "views/admin/gestaotrafego.svelte";
  import Reunioestrafego from "views/admin/reunioestrafego.svelte";
  import Assinaturas from "views/admin/assinaturas.svelte";
  import Alunosdesenhamente from "views/admin/alunosdesenhamente.svelte";
  import Layoutmaker from "views/admin/layoutmaker.svelte";

  export let location;
  // export let admin = "";
</script>

<div>
  <style>
  .icon_menu{
    color: #9ed923;
  }
  .nav_color {
    background-color: #1a1a27; color: #fff; font-family:Arial;
  }
  .submenu {
      background-color: #0b0b1c;
  }
  
  </style>


  <Sidebar location={location}/>
  <div class="relative md:ml-[191px] bg-blueGray-100">
    <AdminNavbar />
    <HeaderStats />
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="dashboard" component="{Dashboard}" />
        <Route path="settings" component="{Settings}" />
        <Route path="tables" component="{Tables}" />
        <Route path="teste" component="{teste}" />
        <Route path="teste2" component="{teste2}" />
        <Route path="maps" component="{Maps}" />
        <Route path="operacional/pedidos" component="{Pedidosdecaricaturas}" />
        <Route path="eventos" component="{Eventos}" />
        <Route path="credenciamentootr" component="{Credenciamentootr}" />
        <Route path="programacaosemanal" component="{Programacaosemanal}" />
        <Route path="caricapedia" component="{Caricapedia}" />
        <Route path="minhacarteira" component="{Minhacarteira}" />
        <Route path="desempenho" component="{Desempenho}" />
        <Route path="desempenho" component="{Reunioes}" />
        <Route path="interacoes" component="{Interacoes}" />
        <Route path="interacoes" component="{Retencoes}" />
        <Route path="interacoes" component="{Saidas}" />
        <Route path="diversas" component="{Diversas}" />
        <Route path="entradanovos" component="{Entradanovos}" />
        <Route path="credenciamentosotr" component="{Credenciamentosotr}" />
        <Route path="funiltypeform" component="{Funiltypeform}" />
        <Route path="designs" component="{Designs}" />
        <Route path="creditosdesign" component="{Creditosdesign}" />
        <Route path="lpvendas" component="{Lpvendas}" />
        <Route path="gestaotrafego" component="{Gestaotrafego}" />
        <Route path="reunioestrafego" component="{Reunioestrafego}" />
        <Route path="assinaturas" component="{Assinaturas}" />
        <Route path="alunosdesenhamente" component="{Alunosdesenhamente}" />
        <Route path="layoutmaker" component="{Layoutmaker}" />


    </Router>


      <!-- <FooterAdmin /> -->
    </div>
  </div>
</div>

