<script>
    import { link } from "svelte-routing";
  
    // core components
    import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
    import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
    import Desenhistas from "../../layouts/Desenhistas.svelte";
  
    let collapseShow = "hidden";
  
    function toggleCollapseShow(classes) {
      collapseShow = classes;
    }
  
    export let location;
  
    function dropdown(event) {
      const submenu = event.currentTarget.nextElementSibling;
      const arrow = event.currentTarget.querySelector(".dropdown-arrow");
  
      submenu.classList.toggle("hidden");
      arrow.classList.toggle("rotate-0");
    }
  
    function handleKeyPress(event) {
      if (event.key === "Enter" || event.key === " ") {
        dropdown(event);
      }
    }
  </script>
  
  <nav
    class="nav_color bg-[#1a1a27] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-menu flex flex-wrap items-center justify-between relative md:w-[190px] z-10 py-4"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
      >
        <i class="fas fa-bars" />
      </button>
      <!-- Brand -->
      <a
        use:link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        href="/"
      >
        <img
          src="https://app.caricanecas.com.br/assets/header-logo.png"
          alt="Caricanecas"
        />
      </a>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <NotificationDropdown />
        </li>
        <li class="inline-block relative">
          <UserDropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <a
                use:link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                href="/"
              >
                Notus Svelte
              </a>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                on:click={() => toggleCollapseShow("hidden")}
              >
                <i class="fas fa-times" />
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>
  
        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
  
  
  
  
  
  
  
  
  
  
  
  
  
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          Desenhistas
        </h6>
  
  
  
  
  
  
        <!-- ul desenhstas -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
  
  
  
  
  
  
            <!-- PÁGINA DASHBOARD -->
          <li class="items-center">
            <a
              use:link
              href="/desenhistas/dashboard"
              class="text-xs uppercase py-3 px-4 font-bold block px-4 {location.href.indexOf(
                '/desenhistas/dashboard'
              ) !== -1
                ? 'text-green-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fab fa-buffer mr-2 text-sm icon_menu {location.href.indexOf(
                  '/desenhistas/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Dashboard
            </a>
          </li>
          <!-- FIM - PÁGINA DASHBOARD -->
  
  
  
  
  
  
  
          <!-- DROPDOWN APP -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/desenhistas/pedidos'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-user-circle mr-2 text-sm icon_menu {location.href.indexOf(
                  '/desenhistas/pedidos'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              APP
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>
  
          <div class="submenu bg-sub-menu hidden">
  
                <!-- PÁGINA PEDIDOS -->
                <a
                use:link
                href="/desenhistas/pedidos"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/desenhistas/pedidos'
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-400 hover:text-blueGray-200'}"
                >
                <i
                    class="fas fa-tools mr-2 text-sm {location.pathname ===
                    '/desenhistas/pedidos'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Pedidos
                </a>

                <!--FIM - PÁGINA PEDIDOS -->
            </div>
            
  
  
            <!-- DROPDOWN SUPORTE -->
            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
            <div
            class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/desenhistas/videoaulas' || location.pathname === '/desenhistas/portaldosdesenhistas' || location.pathname === '/desenhistas/revistacaricanews'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
                <i
                  class="fas fa-chart-line mr-2 text-sm icon_menu {location.href.indexOf(
                    '/admin/dashboard'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Suporte
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>
  
            <div class="submenu bg-sub-menu hidden">
  
  
  
              <!-- PÁGINA VIDEO AULAS -->
              <a
                use:link
                href="/desenhistas/videoaulas"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/desenhistas/videoaulas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/desenhistas/videoaulas'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Video Aulas
              </a>
  
              <!--FIM - PÁGINA VIDEO AULAS -->
  
  
  
  
              <!-- PÁGINA PORTAL DOS DESENHHISTAS -->
              <a
                use:link
                href="/desenhistas/portaldosdesenhistas"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/desenhistas/portaldosdesenhistas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/desenhistas/portaldosdesenhistas'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Portal dos Desenhistas
              </a>
              <!--FIM - PÁGINA PORTAL DOS DESENHISTAS -->
  
  
  
  
  
              <!-- PÁGINA CARICANEWS -->
              <a
                use:link
                href="/desenhistas/revistacaricanews"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/desenhistas/revistacaricanews'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/desenhistas/revistacaricanews'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Revista Caricanews
              </a>
              <!--FIM - PÁGINA REVISTA CARICANEWS -->

                 
                  <!-- FIM DROPDOWN SUPORTE -->
                </div>
  
              </ul>
                <!-- FIM MENU DESENHISTAS -->
      </div>
    </div>
  </nav>
  