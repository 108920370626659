<script>
  import { link } from "svelte-routing";

  // core components
  import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "components/Dropdowns/UserDropdown.svelte";

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  export let location;

  function dropdown(event) {
    const submenu = event.currentTarget.nextElementSibling;
    const arrow = event.currentTarget.querySelector(".dropdown-arrow");

    submenu.classList.toggle("hidden");
    arrow.classList.toggle("rotate-0");
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" || event.key === " ") {
      dropdown(event);
    }
  }
</script>

<nav
  class="nav_color bg-[#1a1a27] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-menu flex flex-wrap items-center justify-between relative md:w-[190px] z-10 py-4"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
    >
      <i class="fas fa-bars" />
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      <img
        src="https://app.caricanecas.com.br/assets/header-logo.png"
        alt="Caricanecas"
      />
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <NotificationDropdown />
      </li>
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              Notus Svelte
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow("hidden")}
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />













      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        ADMINISTRADOR
      </h6>






      <!-- ul ADMIN -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">






          <!-- PÁGINA DASHBOARD -->
        <li class="items-center">
          <a
            use:link
            href="/admin/dashboard"
            class="text-xs uppercase py-3 px-4 font-bold block px-4 {location.href.indexOf(
              '/admin/dashboard'
            ) !== -1
              ? 'text-green-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fab fa-buffer mr-2 text-sm icon_menu {location.href.indexOf(
                '/admin/dashboard'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Dashboard
          </a>
        </li>
        <!-- FIM - PÁGINA DASHBOARD -->







        <!-- DROPDOWN CARICATURAS -->
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <li
          class="items-center dropdown-toggle"
          on:click={dropdown}
          on:keypress={handleKeyPress}
          tabindex="0"
        >
          <div
            class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/operacional/pedidos' ||
            location.pathname === '/admin/teste2' ||
            location.pathname === '/admin/eventos' ||
            location.pathname === '/admin/credenciamentootr'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-user-circle mr-2 text-sm icon_menu {location.href.indexOf(
                '/admin/operacional/pedidos'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Caricaturas
            <span class="text-sm rotate-180 dropdown-arrow">
              <i class="bi bi-chevron-down" />
            </span>
          </div>
        </li>

        <div class="submenu bg-sub-menu hidden">




          <!-- PÁGINA PEDIDOS DE CARICATURAS -->
          <a
            use:link
            href="/admin/operacional/pedidos"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/operacional/pedidos'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm icon_menu {location.pathname ===
              '/admin/operacional/pedidos'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Pedidos de Caricaturas
          </a>
          <!--FIM - PÁGINA PEDIDOS DE CARICATURAS -->



          <!-- PÁGINA CORPINHO PERSONALIZADO -->
          <a
            use:link
            href="/admin/corpinhos-personalizados/corpinhopersonalizado"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/corpinhos-personalizados/corpinhopersonalizado'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm icon_menu {location.pathname ===
              '/admin/corpinhos-personalizados/corpinhopersonalizado'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Corpinhos Personalizados
          </a>
          <!--FIM - PÁGINA CORPINHO PERSONALIZADO -->




          <!-- PÁGINA DE EVENTOS -->
          <a
            use:link
            href="/admin/eventos"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/eventos'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm icon_menu {location.pathname ===
              '/admin/eventos'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Eventos
          </a>
          <!--FIM - PÁGINA DE EVENTOS-->




          <!-- PÁGINA DE CREDENCIAMENTO OTR -->
          <a
            use:link
            href="/admin/credenciamentootr"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/credenciamentootr'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm icon_menu {location.pathname ===
              '/admin/credenciamentootr'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Credenciamento OTR
          </a>
          <!--FIM - PÁGINA DE CREDENCIAMENTO OTR-->





        </div>
        <!-- FIM - DROPDOWN CARICATURAS -->












        <!-- DROPDOWN CARICASHOP -->
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <li
          class="items-center dropdown-toggle"
          on:click={dropdown}
          on:keypress={handleKeyPress}
          tabindex="0"
        >
          <div
            class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/teste' || location.pathname === '/admin/teste2'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm icon_menu {location.href.indexOf(
                '/admin/dashboard'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Caricashop
            <span class="text-sm rotate-180 dropdown-arrow">
              <i class="bi bi-chevron-down" />
            </span>
          </div>
        </li>

        <div class="submenu bg-sub-menu hidden">


          <!-- PÁGINA TESTE -->
          <a
            use:link
            href="/admin/teste"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/teste'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.pathname ===
              '/admin/teste'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            teste
          </a>
          <!--FIM - PÁGINA TESTE -->



          <!-- PÁGINA TESTE3 -->
          <a
            use:link
            href="/admin/teste"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/teste3'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.pathname ===
              '/admin/teste3'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            teste3
          </a>
          <!--FIM - PÁGINA TESTE3 -->




        </div>
        <!-- FIM - DROPDOWN CARICASHOP -->








        <!-- PÁGINA Settings  -->
        <li class="items-center">
          <a
            use:link
            href="/admin/settings"
            class="text-xs uppercase py-3 px-4 font-bold block {location.href.indexOf(
              '/admin/settings'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.href.indexOf(
                '/admin/settings'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Settings
          </a>
        </li>
        <!-- FIM - PÁGINA Settings  -->





        <!-- DROPDOWN MARKETING -->
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <li
          class="items-center dropdown-toggle"
          on:click={dropdown}
          on:keypress={handleKeyPress}
          tabindex="0"
        >
          <div
            class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/caricapedia' ||
            location.pathname === '/admin/programacaosemanal'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-location-arrow mr-2 text-sm {location.href.indexOf(
                '/admin/dashboard'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Marketing
            <span class="text-sm rotate-180 dropdown-arrow">
              <i class="bi bi-chevron-down" />
            </span>
          </div>
        </li>

        <div class="submenu bg-sub-menu hidden">



          <!-- PÁGINA DE PROGRAMAÇÃO SEMANAL -->
          <a
            use:link
            href="/admin/programacaosemanal"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/programacaosemanal'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.pathname ===
              '/admin/programacaosemanal'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Programação Semanal
          </a>
          <!--FIM - PÁGINA DE PROGRAMAÇÃO SEMANAL-->




          <!-- PÁGINA CARICAPEDIA -->
          <a
            use:link
            href="/admin/caricapedia"
            class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/admin/caricapedia'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm {location.pathname ===
              '/admin/caricapedia'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Caricapedia
          </a>
          <!--FIM - PÁGINA CARICAPEDIA -->




        </div>
        <!-- FIM - DROPDOWN MARKETING -->






          <!-- DROPDOWN CS -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/admin/minhacarteira' ||
              location.pathname === '/admin/desempenho' ||
              location.pathname === '/admin/reunioes' ||
              location.pathname === '/admin/interacoes' ||
              location.pathname === '/admin/retencoes' ||
              location.pathname === '/admin/saidas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-chart-line mr-2 text-sm icon_menu {location.href.indexOf(
                  '/admin/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              CS
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>

          <div class="submenu bg-sub-menu hidden">



            <!-- PÁGINA MINHA CARTEIRA -->
            <a
              use:link
              href="/admin/minhacarteira"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/minhacarteira'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/minhacarteira'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Minha Carteira
            </a>

            <!--FIM - PÁGINA MINHA CARTEIRA -->




            <!-- PÁGINA DESEMPENHO -->
            <a
              use:link
              href="/admin/desempenho"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/desempenho'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/desempenho'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Desempenho
            </a>
            <!--FIM - PÁGINA DESEMPENHO -->





            <!-- PÁGINA REUNIÕES -->
            <a
              use:link
              href="/admin/reunioes"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/reunioes'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/reunioes'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Reuniões
            </a>
            <!--FIM - PÁGINA REUNIÕES -->





            <!-- PÁGINA INTERAÇÕES -->
            <a
              use:link
              href="/admin/interacoes"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/interacoes'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/interacoes'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Interações
            </a>
            <!--FIM - PÁGINA INTERAÇÕES -->





            <!-- PÁGINA RETENÇÕES -->
            <a
              use:link
              href="/admin/retencoes"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/retencoes'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/retencoes'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Retenções
            </a>
            <!--FIM - PÁGINA RETENÇÕES -->





            <!-- PÁGINA SAÍDAS -->
            <a
              use:link
              href="/admin/saidas"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/saidas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/saidas'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Saídas
            </a>
            <!--FIM - PÁGINA SAÍDAS-->




          </div>
          <!-- FIM - DROPDOWN CS -->






          <!-- DROPDOWN VENDAS -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0">
            <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/admin/diversas' ||
              location.pathname === '/admin/entradanovos' ||
              location.pathname === '/admin/credenciamentosotr' ||
              location.pathname === '/admin/funiltypeform'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-shopping-bag mr-2 text-sm icon_menu {location.href.indexOf(
                  '/admin/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Vendas
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>




          <div class="submenu bg-sub-menu hidden">




            <!-- PÁGINA DIVERSAS -->
            <a
              use:link
              href="/admin/diversas"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/diversas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/diversas'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Diversas
            </a>
            <!--FIM - PÁGINA DIVERSAS -->




            <!-- PÁGINA ENTRADA NOVOS -->
            <a
              use:link
              href="/admin/entradanovos"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/entradanovos'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/entradanovos'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Entrada Novos
            </a>

            <!--FIM - ENTRADA NOVOS -->

            <!-- PÁGINA CREDENCIAMENTOS OTR -->
            <a
              use:link
              href="/admin/credenciamentosotr"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/credenciamentosotr'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/credenciamentosotr'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Credenciam
            <!--FIM - CREDENCIAMENTOS OTR -->





            <!-- PÁGINA FUNIL TYPEFORM -->
            <a
              use:link
              href="/admin/funiltypeform"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/funiltypeform'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/funiltypeform'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Fúnil Typeform
            </a>
            <!--FIM - FUNIL TYPEFORM  -->





          </div>
          <!-- FIM - DROPDOWN VENDAS -->






          <!-- DROPDOWN SERVIÇOS -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/admin/diversas' || location.pathname === '/admin/entradanovos'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                  '/franqueados/operacional/ferramentas'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Serviços
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>

          <div class="submenu bg-sub-menu hidden">



            <!-- PÁGINA DESIGNS -->
            <a
              use:link
              href="/admin/designs"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/designs'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/designs'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Designs
            </a>

            <!--FIM - DESIGNS  -->

            <!-- PÁGINA CRÉDITOS DE DESIGN -->
            <a
              use:link
              href="/admin/creditosdesign"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/creditosdesign'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/creditodesign'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Créditos de Design
            </a>
            <!--FIM - CRÉDITOS DE DESIGN  -->




            <!-- PÁGINA LP VENDAS -->
            <a
              use:link
              href="/admin/lpvendas"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/lpvendas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/lpvendas'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              LP Vendas
            </a>
            <!--FIM - LP VENDAS  -->




            <!-- PÁGINA GESTÃO DE TRÁFEGO-->
            <a
              use:link
              href="/admin/gestaotrafego"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/gestaotrafego'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/gestaotrafego'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Gestão de Tráfego
            </a>
            <!--FIM - GESTÃO DE TRÁFEGO -->




            <!-- PÁGINA REUNIÕES DE TRÁFEGO-->
            <a
              use:link
              href="/admin/reunioestrafego"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/reunioestrafego'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/reunioestrafego'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Reuniões de Tráfego
            </a>
            <!--FIM - REUNIÕES DE TRÁFEGO -->



          </div>
          <!-- FIM - DROPDOWN SERVIÇOS -->




          <!-- DROPDOWN CARICAVIP -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/assinaturas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-gift mr-2 text-sm icon_menu {location.href.indexOf(
                  '/admin/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              CaricaVIP
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>

          <div class="submenu bg-sub-menu hidden">




            <!-- PÁGINA ASSINATURAS-->
            <a
              use:link
              href="/admin/assinaturas"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/assinaturas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/assinaturas'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Assinaturas
            </a>
            <!--FIM - ASSINATURAS -->




          </div>
          <!-- FIM - DROPDOWN CARICAVIP -->





          <!-- DROPDOWN TREINAMENTOS -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/alunosdesenhamente'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-video mr-2 text-sm icon_menu {location.href.indexOf(
                  '/admin/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Treinamentos
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>

          <div class="submenu bg-sub-menu hidden">


            <!-- PÁGINA ALUNOS DESENHAMENTE-->
            <a
              use:link
              href="/admin/alunosdesenhamente"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/alunosdesenhamente'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/alunosdesenhamente'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Alunos Desenhamente
            </a>
            <!--FIM - ALUNOS DESENHAMENTE -->



          </div>
          <!-- FIM - DROPDOWN TREINAMENTOS -->





          <!-- DROPDOWN FERRAMENTAS -->
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <li
            class="items-center dropdown-toggle"
            on:click={dropdown}
            on:keypress={handleKeyPress}
            tabindex="0"
          >
            <div
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/ferramentas'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm icon_menu {location.href.indexOf(
                  '/admin/dashboard'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Ferramentas
              <span class="text-sm rotate-180 dropdown-arrow">
                <i class="bi bi-chevron-down" />
              </span>
            </div>
          </li>

          <div class="submenu bg-sub-menu hidden">



            <!-- PÁGINA LAYOUTMAKER-->
            <a
              use:link
              href="/admin/layoutmaker"
              class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/admin/layoutmaker'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.pathname ===
                '/admin/layoutmaker'
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"
              />
              Layoutmaker
            </a>
            <!--FIM - LAYOUTMAKER -->



          </div>
          <!-- FIM - DROPDOWN FERRAMENTAS -->


</ul>
          <!-- FIM MENU ADM -->


          









          <!-- Heading -->
          <h6
            class="mt-10 md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          >
            MENU FRANQUEADOS
          </h6>

          <!-- Navigation -->

          <ul class="md:flex-col md:min-w-full flex flex-col list-none">
            <li class="items-center">
              <a
                use:link
                href="/franqueados/dashboard"
                class="text-xs uppercase py-3 px-4 font-bold block px-4 {location.href.indexOf(
                  '/franqueados/dashboard'
                ) !== -1
                  ? 'text-green-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/dashboard'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Dashboard
              </a>
            </li>

            <!-- FIM - PÁGINA DASHBOARD -->

            <!-- DROPDOWN CARICATURAS -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              
            <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/pedidos'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/pedidos'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Caricaturas
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA PEDIDOS DE CARICATURAS -->
              <a
                use:link
                href="/franqueados/operacional/pedidos"
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/pedidos'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/pedidos'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Pedidos de caricaturas
              </a>

              <!--FIM - PÁGINA PEDIDOS DE CARICATURAS -->
            </div>

            <!-- FIM - CARICATURAS-->

            <!-- DROPDOWN TREINAMENTOS -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
            <div
            class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
            '/franqueados/operacional/cursos' || location.pathname ===
            '/franqueados/operacional/atualizacoesmensais' || location.pathname ===
            '/franqueados/operacional/reuniaotrafego' || location.pathname ===
            '/franqueados/operacional/reuniaosemanal'
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-400 hover:text-blueGray-200'}"
          >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/franqueados'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Treinamentos
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA DE CURSOS -->
              <a
                use:link
                href="/franqueados/operacional/cursos"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/cursos'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/cursos'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Cursos
              </a>

              <!--FIM - PÁGINA PEDIDOS DE CURSOS-->

              <!-- PÁGINA ATT MENSAIS -->
              <a
                use:link
                href="/franqueados/operacional/atualizacoesmensais"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/atualizacoesmensais'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/atualizacoesmensais'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Atualizações Mensais
              </a>

              <!--FIM - PÁGINA ATT MENSAIS-->

              <!-- PÁGINA REUNIÕES TRÁFEGO -->
              <a
                use:link
                href="/franqueados/operacional/reuniaotrafego"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/admin/franqueados/reuniaotrafego'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/admin/franqueados/reuniaotrafego'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Reunião de Tráfego
              </a>

              <!--FIM - PÁGINA REUNIÕES TRÁFEGO-->

              <!-- PÁGINA REUNIÕES SEMANASIS -->
              <a
                use:link
                href="/franqueados/operacional/reuniaosemanal"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/reuniaosemanal'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/reuniaosemanal'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Reunião Semanal
              </a>

              <!--FIM - PÁGINA REUNIÃO SEMANAL-->
            </div>

            <!-- FIM - TREINAMENTOS -->

            <!-- DROPDOWN MARKETING -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              <div
              class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
              '/franqueados/operacional/programacaosemanal' || location.pathname ===
              '/franqueados/operacional/campanhasdemarketing' || location.pathname ===
              '/franqueados/operacional/caricapedia' || location.pathname ===
              '/franqueados/operacional/materialdeapoio'
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-400 hover:text-blueGray-200'}"
            >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/marketing'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Marketing
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA PROGRAMAÇÃO SEMANAL -->
              <a
                use:link
                href="/franqueados/operacional/programacaosemanal"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/programacaosemanal'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/programacaosemanal'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Programação Semanal
              </a>

              <!--FIM - PROGRAMAÇÃO SEMANAL-->




              <!-- PÁGINA CAMPANHAS DE MARKETING -->
              <a
                use:link
                href="/franqueados/operacional/campanhasdemarketing"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/campanhasdemarketing'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/campanhasdemarketing'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Campanhas de Marketing
              </a>

              <!--FIM - CAMPANHAS DE MARKETING -->

              <!-- PÁGINA CARICAPEDIA -->
              <a
                use:link
                href="/franqueados/operacional/caricapedia"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/caricapedia'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/caricapedia'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Caricapedia
              </a>

              <!--FIM - CARICAPEDIA -->

              <!-- PÁGINA MATERIAL DE APOIO -->
              <a
                use:link
                href="/franqueados/operacional/materialdeapoio"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/materialdeapoio'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/materialdeapoio'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Material de Apoio
              </a>
              <!--FIM PÁGINA MATERIAL DE APOIO -->




            </div>
            <!-- FIM - DROPDOWN MARKETING -->




            

            <!-- DROPDOWN SERVIÇOS -->
            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/operacional/design' ||
                location.pathname === '/franqueados/operacional/gestaodetrafego'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/servicos'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Serviços
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA DESIGN -->
              <a
                use:link
                href="/franqueados/operacional/design"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/design'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/design'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Design
              </a>

              <!--FIM PÁGINA  DESIGN-->

              <!-- PÁGINA GESTÃO TRÁFEGO -->
              <a
                use:link
                href="/franqueados/operacional/gestaodetrafego"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/gestaodetrafego'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/gestaodetrafego'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Gestão de Tráfego
              </a>

              <!--FIM PÁGINA GESTÃO TRÁFEGO -->
            </div>

            <!-- FIM - DROPDOWN SERVIÇOS -->

            <!-- DROPDOWN E-COMMERCE -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                  '/franqueados/operacional/vendasonline' ||
                location.pathname === '/franqueados/operacional/produtos' ||
                location.pathname === '/franqueados/operacional/clientes' ||
                location.pathname ===
                  '/franqueados/operacional/configuracoes' ||
                location.pathname === '/franqueados/operacional/cupons' ||
                location.pathname === '/franqueados/operacional/ecommerce'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/ecommerce'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                e-commerce
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA VENDAS ONLINE -->
              <a
                use:link
                href="/franqueados/operacional/vendasonline"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/vendasonline'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/vendasonline'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Vendas Online
              </a>

              <!--FIM PÁGINA VENDAS ONLINE -->

              <!-- PÁGINA PRODUTOS -->
              <a
                use:link
                href="/franqueados/operacional/produtos"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/produtos'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/produtos'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Produtos
              </a>

              <!--FIM PÁGINA PRODUTOS -->

              <!-- PÁGINA CLIENTES -->
              <a
                use:link
                href="/franqueados/operacional/clientes"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/clientes'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/clientes'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Clientes
              </a>

              <!--FIM PÁGINA CLIENTES  -->

              <!-- PÁGINA CONFIGURAÇÕES -->
              <a
                use:link
                href="/franqueados/operacional/configuracoes"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/configuracoes'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/configuracoes'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Configurações
              </a>

              <!--FIM PÁGINA CONFIGURAÇÕES  -->

              <!-- PÁGINA CUPONS -->
              <a
                use:link
                href="/franqueados/operacional/cupons"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/cupons'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/cupons'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Cupons
              </a>

              <!--FIM PÁGINA CUPONS  -->

              <!-- PÁGINA SALDO E-COMMERCE -->
              <a
                use:link
                href="/franqueados/operacional/ecommerce"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/ecommerce'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/ecommerce'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                e-commerce
              </a>

              <!--FIM PÁGINA E-COMMERCE  -->
            </div>

            <!--FIM DROPDOWN E-COMMERCE  -->

            <!-- DROPDOWN FERRAMENTAS -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/lpvendas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/ferramentas'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Ferramentas
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA LP VENDAS -->
              <a
                use:link
                href="/franqueados/operacional/lpvendas"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/lpvendas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/lpvendas'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                LP Vendas
              </a>

              <!--FIM PÁGINA LP VENDAS -->
            </div>

            <!--FIM PÁGINA DROPDOWN FERRAMENTAS -->

            <!-- DROPDOWN FINANCEIRO -->

            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <li
              class="items-center dropdown-toggle"
              on:click={dropdown}
              on:keypress={handleKeyPress}
              tabindex="0"
            >
              <div
                class="cursor-pointer text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/minhascobrancas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                    '/franqueados/operacional/financeiro'
                  ) !== -1
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Financeiro
                <span class="text-sm rotate-180 dropdown-arrow">
                  <i class="bi bi-chevron-down" />
                </span>
              </div>
            </li>

            <div class="submenu bg-sub-menu hidden">
              <!-- PÁGINA MINHAS COBRANÇAS -->
              <a
                use:link
                href="/franqueados/operacional/minhascobrancas"
                class="text-xs uppercase py-3 px-4 font-bold block {location.pathname ===
                '/franqueados/operacional/minhascobrancas'
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-blueGray-400 hover:text-blueGray-200'}"
              >
                <i
                  class="fas fa-tools mr-2 text-sm {location.pathname ===
                  '/franqueados/operacional/minhascobrancas'
                    ? 'opacity-75'
                    : 'text-blueGray-300'}"
                />
                Minhas Cobranças
              </a>

              <!--FIM PÁGINA MINHAS COBRANÇAS -->
            </div>

            <!--FIM PÁGINA DROPDOWN FINANCEIRO -->


          </ul>
            <!-- FIM MENU FRANQUEADOS -->

            
    </div>
  </div>
</nav>
